<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getAdjustStock"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-9 col-sm-9 col-8 col-xl-10 d-inline-flex align-items-center">
              <h2 class="font-weight-normal">
                {{ $t('management.adjustStockDoc') }}
              </h2>
              <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div v-if="isEdit" class="col-md-3 col-sm-3 col-4 col-xl-2 text-right">
              <CButton
                id="AdjustStockAddButton"
                to="/inventory/adjust-stock/create"
                block
                color="warning" 
                style="color: white; display: flex; align-items: center; justify-content: center;">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i> {{ $t('management.adjustStock') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">              
              <p class="mt-2">{{ $t("moreInfo") }} <i class="fi fi-rr-info ml-1" @click="openHelpModal = true"></i></p>
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
                <CDataTable
                  :items="items"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  clickableRows
                  @row-clicked="adjustStockDocumentDetail"
                  style="min-width: max-content; white-space: nowrap;"
                  id="AdjustStockDataTable"
                >
                  <template #detail="{item, index}">
                    <td class="py-2 text-center">
                      <CButton
                        :to="`/inventory/adjust-stock/${item.objectId}`"
                        color="info"
                        square
                        size="sm"
                        :id="'AdjustStockDetailButton' + index"
                      >
                        <i class="fa fa-info" aria-hidden="true"></i>
                      </CButton>
                    </td>
                  </template>
                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                </CDataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getAdjustStock">
          </pagination>
        </CCardBody>
      </CCard>
      <CModal
        :show.sync="openHelpModal"
        centered
        :title="$t('userManual')"
        color="success"
        header="false"
        size="lg"
      >
        <div class="col-12 p-4" >
          <h4 class="text-center">{{ $t("adjustGuideTitle") }}</h4>
          <p class="h5">
            <div class="d-flex justify-content-center">
              <!-- <img src="/img/helpTransferIn.jpg" class="img-fluid p-2 mb-2" /> -->
            </div>            
              <div class="" style="font-weight:1000; font-size: 18px;">{{ $t('adjustMethodsIntro') }} :</div>
              <div  style="font-weight:1000; font-size: 18px;">{{ $t('adjustStepsTitle') }} </div>
              <ol style="font-size: 16px;">
                <li>{{ $t('adjustStep1') }}</li>
                <li>{{ $t('adjustStep2') }}</li>
                <li>{{ $t('adjustStep3') }}</li>
              </ol>        
            </p>
        </div>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4" >
              <CButton  id="AdjustStockHelpModalSubmitButton" @click="openHelpModal = false" color="outline-success"  block>
                <b>{{ $t("submit") }}</b>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/local'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'
import HelpButton from '../../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    HelpButton
  },
  data() {
    return {
      data: [],
      loadingButton: true,
      openHelpModal: false,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'access']),
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isEdit() {
      return permis.findPermissionEdit('inventory', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'font-weight-normal text-dark' },
        { key: 'documentNo', label: this.$i18n.t('documentNo'), _classes: 'font-weight-normal text-dark' },
        { key: 'adjustDocType', label: this.$i18n.t('transactionType'), _classes: 'font-weight-normal text-dark' },
        {
          key: 'username',
          label: this.$i18n.t('saveBy'),
          _classes: 'text-left font-weight-normal text-dark',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let adjustDocType = item.adjustDocType || ''
        if (adjustDocType === 1) {
          adjustDocType = this.$i18n.t('adjustDocType1')
        } else if (adjustDocType === 2) {
          adjustDocType = this.$i18n.t('adjustDocType2')
        }
        const documentNo = item.documentNo || ''
        const createdBy = item.created_by || {}
        
        let createdAt = ''
        if (item.created_at != null) {
          createdAt = moment(String(item.created_at)).format('DD/MM/YYYY HH:mm:ss')
        }
        
        detail.push({
          objectId: item.objectId,
          date: createdAt,
          documentNo: documentNo,
          adjustDocType: adjustDocType,
          username: createdBy.username || '',
        })
      }
      return detail
    },
  },
  created() {
    this.getAdjustStock()
  },
  methods: {
    getAdjustStock(page = 1) {
      this.loadingButton = false
      this.loading = true

      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/getadjuststock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.documents
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    adjustStockDocumentDetail(item, index, event) {
      this.$router.push('/inventory/adjust-stock/' + item.objectId)
    },
  },
}
</script>
<style src="../../../assets/styles/inventory.css" scoped></style>
